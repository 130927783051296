import React from "react"

import "../../../styles.scss"


import Shopmatics from "../../../components/shopmatics/shopmatic"


const Shopmatic = () => {
  return (
    <>
     <Shopmatics></Shopmatics>
    </>
  )
}

export default Shopmatic
