import React from "react"
import styles from "./shopmatic-css-module.module.scss"
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet"
import {
    Layout,
    WhySolv,
    Button,
    SEO
} from "../../components"

import fsbannerImg from "../../../static/sm_1.png"
import smAppImg from "../../../static/sm_2.png"
import CheckpointLightBlue from "../../../static/checkpoint-lb.svg"
import CheckpointGrey from "../../../static/checkpoint-grey.svg"
import IndCat from "../../../static/sm_3_indCat.png"
import IndCat1 from "../../../static/indcat1.png"
import IndCat2 from "../../../static/indcat2.png"
import shopmaticLogo from "../../../static/logo-dark-neo.svg"
import chat from "../../../static/shopmatic/Chat.png"
import marketplace from "../../../static/shopmatic/Marketplace.png"
import social from "../../../static/shopmatic/Social.png"
import webstore from "../../../static/shopmatic/Webstore.png"


const Shopmatics = () => {

    let track_source;

    if (typeof window !== `undefined`){
         track_source = new URLSearchParams(window.location.search).get('utmmedium');
    }

    const isProduction = process.env.PRODUCTION === 'true';


    function goToWebSignUp() {
        window.Moengage.track_event('Track_Module_Source', { utm_source:  track_source || '' })
        window.open('https://myshopmatic.com/signup/select_country/72J1JM/50RO30?home_country=in&home_country=in&partner=solv');
    }

    function goToAppSignUp() {

        window.Moengage.track_event('Track_Module_Source', { utm_source: track_source || '' })
        window.open('https://shopmaticgoapp.page.link/partner_solv');
    }

    function goToGetDetails() {
        window.Moengage.track_event('Track_Module_Source', { utm_source: track_source || '' })
        window.open('https://myshopmatic.com/signup/select_country/72J1JM/50RO30?home_country=in&home_country=in&partner=solv');
    }

    return (
        <>
            <Layout>
                <Helmet>
                    <script>
                        {`
                        (function (i, s, o, g, r, a, m, n) {
                            i.moengage_object = r; t = {};
                            q = function (f) {
                                return function () {
                                    (i.moengage_q = i.moengage_q || []).push({ f: f, a: arguments })
                                }
                            };
                            f = ['track_event',
                            'add_user_attribute',
                            'add_first_name',
                            'add_last_name',
                            'add_email',
                            'add_mobile',
                            'add_user_name',
                            'add_gender',
                            'add_birthday',
                            'destroy_session',
                            'add_unique_user_id',
                            'moe_events',
                            'call_web_push',
                            'track', 'location_type_attribute'],
                            h = { onsite: ["getData", "registerCallback"] };
                            for (k in f) { t[f[k]] = q(f[k]) } for (k in h)
                            for (l in h[k]) {
                                null == t[k] && (t[k] = {}),
                                t[k][h[k][l]] = q(k + "." + h[k][l])
                            }
                            a = s.createElement(o);
                            m = s.getElementsByTagName(o)[0];
                            a.async = 1; a.src = g;
                            m.parentNode.insertBefore(a, m);
                            i.moe = i.moe || function () { n = arguments[0]; return t };
                            a.onload = function () {
                                if (n) { i[r] = moe(n) }
                            }
                        })(window, document, 'script', 'https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js', 'Moengage')
                        
                        Moengage = moe({
                            app_id: "TTUKBTJM8865YXIQFHHKUZOJ", // here goes your App Id
                            debug_logs: ${isProduction  ? 0 : 1},
                            cluster: 'DC_3'
                        })
                        
                        Moengage.add_email('test@solvezy.com');
                        `}
                    </script>
                </Helmet>
                <SEO
                    title="Shopmatic"
                    description="Solv enables MSMEs to get access to timely finance via a marketplace model. Solv is able to help small businesses get quick and easy access to credit."
                />
                <section>
                    <div className={styles.containerTop}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.heading}>
                                    Establish your brand online with Shopmatic
                                </div>
                                <div className={styles.content}>
                                    Solv has entered into a partnership with leading ecommerce platform Shopmatic. An internationally acclaimed ecommerce platform, Shopmatic helps small and medium businesses and entrepreneurs take their business online.
                                </div>
                                <div className={styles.content}>
                                    It has helped 1 million customers go online and is headquartered in Singapore with a presence in India, Hong Kong, China, Malaysia, Philippines and United Arab Emirates.
                                </div>
                                <div className={styles.button}>

                                    <div className={styles.smNone}>
                                        <div className={styles.signUp}>
                                            <Button
                                                label="Sign Up &nbsp; &#8594;"
                                                handleClick={goToWebSignUp}
                                            />
                                        </div>

                                        <div>
                                            <img src={shopmaticLogo} alt="logo" />
                                        </div>
                                    </div>

                                    <div className={styles.lgNone} >
                                        <div className={styles.signUp}>

                                            <div>
                                                <Button
                                                    label="Sign Up &nbsp; &#8594;"
                                                    handleClick={goToAppSignUp}
                                                />
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className={styles.col}>
                                <img src={fsbannerImg} alt="logo" />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className={styles.iconContainer}>
                        <div className={styles.icons}>
                            <div className={styles.allignIcons}>
                                <img src={chat} />
                                <div>
                                    <span>
                                        Shopmatic Chat
                                    </span>
                                </div>

                            </div>
                            <div className={styles.allignIcons}>
                                <img src={webstore} />
                                <div>
                                    <span>
                                        Shopmatic Webstore
                                    </span>
                                </div>

                            </div>
                            <div className={styles.allignIcons}>
                                <img src={social} />
                                <div>
                                    <span>
                                        Shopmatic Social
                                    </span>
                                </div>

                            </div>
                            <div className={styles.allignIcons}>
                                <img src={marketplace} />
                                <div>
                                    <span>
                                        Shopmatic Marketplace
                                    </span>
                                </div>


                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className={styles.containerShopmatic}>
                        <div className={styles.row}>
                            <div className={styles.smNone}>
                                <div className={styles.col}>
                                    <img src={smAppImg} alt="shopmaticAppImg" />
                                </div>
                            </div>
                            <div className={styles.col}>
                                <div className={styles.heading}>
                                    What is Shopmatic ?
                                </div>
                                <div className={styles.content}>
                                    Shopmatic is the only company in the world to offer 4 different ways to do e-commerce: Shopmatic Chat, Shopmatic Social, Shopmatic Webstore and Shopmatic Marketplaces.
                                </div>
                                <div className={styles.lgNone}>
                                    <img src={smAppImg} alt="shopmaticAppImg" />
                                </div>
                                <div className={styles.content}>
                                    Shopmatic enables the entire ecosystem for anyone wanting to sell online, with pre-designed templates, multiple payment and shipping options, social and chat commerce and many more features.
                                </div>
                                <div className={styles.content}>
                                    Shopmatic offers you a quick and efficient solution that requires no coding or programming knowledge at your end. It helps you take your business online so you can focus solely on growing your business.
                                </div>

                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.shopmaticOffers}>
                                    <div className={styles.solutionContainer}>
                                        <div className={[styles.reason, styles.one].join(" ")}>
                                            <WhySolv
                                                icon="shopmatic1"
                                                number="1"
                                                label="Shopmatic Chat"
                                                description="Turn conversations to conversions via Shopmatic Chat. Close sales with the Single Checkout Link - complete with payments & shipping, on WhatsApp, Messenger, Telegram, LINE or any other Chat tools."
                                            />
                                        </div>
                                        <div className={[styles.reason, styles.three].join(" ")}>
                                            <WhySolv
                                                icon="shopmatic3"
                                                number="3"
                                                label="Shopmatic Webstore"
                                                description="Create your online store without any coding with Shopmatic’s best ecommerce solution. Our ecommerce website builder is loaded with features like International Payments, Shipping, Chat, Social and Marketplace selling (available only on browser)."
                                            />
                                        </div>
                                        <div className={[styles.reason, styles.two].join(" ")}>
                                            <WhySolv
                                                icon="shopmatic2"
                                                number="2"
                                                label="Shopmatic Social"
                                                description="Selling on Facebook and Instagram just got easier. Convert followers to shoppers with Shopmatic Social. Use the Single Checkout Link - complete with payments and shipping, and close sales instantly."
                                            />
                                        </div>
                                        <div className={[styles.reason, styles.four].join(" ")}>
                                            <WhySolv
                                                icon="shopmatic4"
                                                number="4"
                                                label="Shopmatic Marketplace"
                                                description="Sell on multiple marketplaces from the Shopmatic platform. List your products on Lazada, Shopee, Qoo10 and Amazon. Manage all your marketplace business from one platform."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className={styles.containerKeyBenefits}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.heading}>
                                    Key Benefits
                                </div>
                                <div className={styles.content}>
                                    Shopmatic helps business owners manage the full spectrum of what is required to grow their business.
                                </div>
                            </div>
                        </div>

                        <div className={styles.row}>

                            <div className={styles.col + 'pb-none'}>
                                <div className={styles.kbCheckpoints}>
                                    <div className={styles.checkPointItem}>
                                        <img src={CheckpointLightBlue} />
                                        <span className="checkpoint-text">60+ free pre-designed templates</span>
                                    </div>
                                    <div className={styles.checkPointItem}>
                                        <img src={CheckpointLightBlue} />
                                        <span className="checkpoint-text">
                                            Easy integrations with domestic and international payment gateways and shipping partners
                                        </span>
                                    </div>
                                    <div className={styles.checkPointItem}>
                                        <img src={CheckpointLightBlue} />
                                        <span className="checkpoint-text">Domain name</span>
                                    </div>
                                    <div className={styles.checkPointItem}>
                                        <img src={CheckpointLightBlue} />
                                        <span className="checkpoint-text">Integrated Chat Tools</span>
                                    </div>
                                </div>
                            </div>


                            <div className={styles.col + 'pt-none'}>
                                <div className={styles.kbCheckpoints}>
                                    <div className={styles.checkPointItem}>
                                        <img src={CheckpointLightBlue} />
                                        <span className="checkpoint-text">
                                            Get discovered via a unique, curated platform- Shopmatic World.
                                        </span>
                                    </div>
                                    <div className={styles.checkPointItem}>
                                        <img src={CheckpointLightBlue} />
                                        <span className="checkpoint-text">
                                            POS, Inventory Management and CRM solutions
                                        </span>
                                    </div>
                                    <div className={styles.checkPointItem}>
                                        <img src={CheckpointLightBlue} />
                                        <span className="checkpoint-text">
                                            Chat & Social Commerce
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.mt5}>
                                    <div className={styles.centerHeading}>
                                        Who can go online with Shopmatic?
                                    </div>
                                    <div className={styles.centerContent}>
                                        Any seller on Solv can set up their ecommerce presence with Shopmatic.
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.mt5}>
                                    <div className={styles.centerHeading}>
                                        Industries / Categories
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.smNone}>
                                    <img src={IndCat} />


                                </div>
                                <div className={styles.lgNone}>
                                    <img src={IndCat1} />
                                    <img src={IndCat2} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.signUpCenter}>
                            <div className={styles.row}>
                                <div className={styles.lgContainer2}>
                                    <div className={styles.signUp}>
                                        <Button
                                            label="Sign Up &nbsp; &#8594;"
                                            handleClick={goToWebSignUp}
                                        />
                                    </div>

                                    <div>
                                        <img src={shopmaticLogo} alt="logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.lgNone}>
                                <div className={styles.col}>
                                    <div className={styles.signUp}>
                                        <div>
                                            <Button
                                                label="Sign Up &nbsp; &#8594;"
                                                handleClick={goToAppSignUp}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.stepsHeading}>
                                    Easy Steps to set up your presence on Shopmatic:
                                </div>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>

                                <ReactPlayer
                                    url='https://www.youtube.com/watch?v=q-cOYs0SVt8'
                                    className='react-player'
                                    width="100%"
                                />

                            </div>
                            <div className={styles.col}>
                                <div className={styles.row}>

                                    <div className={styles.kbCheckpoints}>
                                        <div className={styles.checkPointItem}>
                                            <img src={CheckpointGrey} />
                                            <span className="checkpoint-text">Step 1 - Download the app and Sign up or Sign up on the Browser</span>
                                        </div>
                                        <div className={styles.checkPointItem}>
                                            <img src={CheckpointGrey} />
                                            <span className="checkpoint-text">
                                                Step 2 - Select a channel - Shopmatic Webstore, Chat, Social or Marketplaces
                                            </span>
                                        </div>
                                        <div className={styles.checkPointItem}>
                                            <img src={CheckpointGrey} />
                                            <span className="checkpoint-text">Step 3 - Add products </span>
                                        </div>
                                        <div className={styles.checkPointItem}>
                                            <img src={CheckpointGrey} />
                                            <span className="checkpoint-text">Step 4 - Select Payment integrations</span>
                                        </div>
                                        <div className={styles.checkPointItem}>
                                            <img src={CheckpointGrey} />
                                            <span className="checkpoint-text">
                                                Step 5 - Select Shipping integrations
                                            </span>
                                        </div>
                                        <div className={styles.checkPointItem}>
                                            <img src={CheckpointGrey} />
                                            <span className="checkpoint-text">
                                                Step 6 - Choose domain
                                            </span>
                                        </div>
                                        <div className={styles.checkPointItem}>
                                            <img src={CheckpointGrey} />
                                            <span className="checkpoint-text">
                                                Step 7 - Publish
                                            </span>
                                        </div>


                                    </div>
                                </div>

                                <div className={styles.row}>

                                    <div className={styles.lgContainer3}>
                                        <div className={styles.signUp}>
                                            <Button
                                                label="Sign Up &nbsp; &#8594;"
                                                handleClick={goToWebSignUp}
                                            />
                                        </div>

                                        <div>
                                            <img src={shopmaticLogo} alt="logo" />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.lgNone}>
                                        <div className={styles.col}>
                                            <div className={styles.signUp}>
                                                <div>
                                                    <Button
                                                        label="Sign Up &nbsp; &#8594;"
                                                        handleClick={goToAppSignUp}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </section>
                <section>
                    <div className={styles.container}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.centerHeading}>
                                    Shopmatic is available in two pricing models
                                </div>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <div className={styles.contactCard}>
                                    <div>
                                        <span className={styles.contact_heading}>The Transaction Model</span>
                                    </div>
                                    <div className={styles.margin_top_5}>
                                        <span className={styles.contact_subheading}>Now, you don’t need to pay Monthly Hosting Fees. Only pay <span className={styles.textOrange}>3%</span>  transaction fee on every sale.</span>
                                    </div>

                                    <div className={styles.cardButton1}>
                                        <Button
                                            label="Sign Up &nbsp; &#8594;"
                                            handleClick={goToAppSignUp}
                                        />
                                    </div>
                                    <div className={styles.cardButton2}>
                                        <Button
                                            label="Sign Up &nbsp; &#8594;"
                                            handleClick={goToWebSignUp}
                                        />
                                    </div>


                                </div>
                            </div>
                            <div className={styles.col}>
                                <div className={styles.contactCard}>
                                    <div>
                                        <span className={styles.contact_heading}>The Subscription Model</span>
                                    </div>
                                    <div className={styles.margin_top_5}>
                                        <span className={styles.contact_subheading}>3 plans for you to choose from. Pay no transaction fees with this model.</span>
                                    </div>

                                    <Button
                                        label="Sign Up &nbsp; &#8594;"
                                        handleClick={goToGetDetails}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Shopmatics